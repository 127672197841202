.select2-container li:first-child .select2-search__field, .select2-selection__rendered li:first-child.select2-search, .select2-container .select2-input{
    width: 100% !important
}
.select2-container--open{
    z-index:99999;
}
/*
.select2.select2-container--open .select2-selection, .select2.select2-container--focus .select2-selection{ border-color:#1faad1; display:flex; flex-wrap: wrap;}
.select2-container .select2-selection--single, .select2-container .select2-selection--multiple{border-color: #e4e6ef; min-height: calc(1.5em + 1.3rem + 2px); max-height: 100px; overflow: auto;display:flex;}
.select2-selection__choice{overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
.select2-container .select2-selection--single .select2-selection__rendered{line-height: 26px !important;}
.select2-container .select2-selection--single .select2-selection__arrow{height: calc(1.5em + 1.3rem + 2px);}
.select2-container .select2-selection--single .select2-selection__rendered{padding: 0.375rem 0.75rem;max-width: 95%;}

.select2-container .select2-selection--single .select2-selection__clear{padding-right:5px;height:calc(1.5em + 1.3rem);}
.select2-container .select2-selection--multiple .select2-selection__rendered{ display:flex;margin-bottom:0px;align-items: center;flex-wrap: wrap;}
.select2-container .select2-selection--single .select2-selection__placeholder{color: #786fa4;}
.select2 .select2-selection--single .select2-selection__arrow b{border-top-color: #d2d9e5 !important;}
.select2-container .select2-selection--single{ border: 1px solid #e4e6ef;}
.select2-container--default .select2-search--inline .select2-search__field{margin-top: 8px;}
.select2-container--default .select2-search--inline { flex:auto;}
.select2-container .select2-search--inline .select2-search__field{ font-family: Poppins, Helvetica, "sans-serif";}*/

.select2-container .select2-selection--multiple{ min-height: 42.94px;display: flex !important;}
.select2-results__options li:nth-of-type(odd){background: #f2f4f8;}
.select2-results__option:not(:last-child){
    border-bottom: 1px solid #aaaaaa;
}
.select2-results__option{
    word-break: keep-all;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.select2-container--bootstrap5 .select2-dropdown{
    padding:0 !important;
}

.select2-container--bootstrap5 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove{
    background-image: initial;
}

.select2-container--bootstrap5 .select2-selection--multiple:not(.form-select-sm):not(.form-select-lg){
    padding-right: 4rem;
}
.select2-container--bootstrap5 .select2-selection--multiple:not(.form-select-sm):not(.form-select-lg) .select2-selection__choice{
    padding-left: 1.5rem !important;
}
.select2-container--bootstrap5 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice{ max-width:90%;overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
.select2-container--bootstrap5 .select2-selection--multiple .select2-selection__rendered{
    display: flex !important;
    flex-wrap: wrap;
    max-height: 100px; 
    overflow: auto;
}

.select2-results__option[aria-selected="true"]{
    background-color:#cccccc !important;
}