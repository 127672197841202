.spinner_button_loader  {
    display: inline-block;
    position: relative;
    -webkit-animation:spin 1.2s linear infinite;
    -moz-animation:spin 1.2s linear infinite;
    animation:spin 1.2s linear infinite;
    -webkit-transition: opacity 0.25s, width 0.25s;
    -moz-transition: opacity 0.25s, width 0.25s;
    -o-transition: opacity 0.25s, width 0.25s;
    transition: opacity 0.25s, width 0.25s;
}

.has-spinner.active {
    cursor:progress;
}

.has-spinner.active .spinner {
    opacity: 1;
    width: auto;
}

.has-spinner.btn.active .spinner {
    min-width: 60px;
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
