.form-check{
    padding-bottom: 0.5rem !important;
}
.page-item.active .page-link {
    z-index: 1!important;
}
.custom-menu-link{
    font-size: 15px;
    font-weight: 700;
}
.custom-menu-bg{
    background-color: #36508e !important;
}
.custom-menu-title-color{
    color: #ffffff;
}
.custom-menu-title-color:hover{
    color: #00577f !important;
}
.custom-menu-item-hover-bg:hover{
    background-color: #ffffff !important;
}

.custom-menu-hover-link:hover{
    color: #00577f !important;
}

.menu-state-bg .menu-item.show > .menu-link {
    background-color: #ffffff !important;
}
.menu-state-bg .menu-item.show > .custom-menu-title-color {
    color: #00577f !important;
}

.enviar-cartas-container{
    background-image: linear-gradient(90deg,#36508e 0%,rgb(0, 133, 202) 100%);
    border-radius: 0.475rem;
}

.custom-tabs-link{
    color: #36508e !important;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 700;
}

.custom-tabs-link:hover{
    color: rgb(0, 133, 202) !important;
}
.enviar-cartas-link{
    color: #ffffff;
    font-size: 15px;
    font-weight: 700;
    border: solid 1px #36508e;
}
.enviar-cartas-link:hover{
    color: rgb(0, 133, 202);
    background-color: #ffffff !important;
}
.enviar-cartas-link-2{
    color: rgb(0, 133, 202);
    background-color: #ffffff !important;
    border: solid 1px #36508e;
    border-radius: 0.475rem;
}

.header-container-margin-negative > .custom-menu-bg{
    background-color: #ffffff !important;
}
.header .header-menu{
    background-color: #ffffff !important;
}

.content-no-padding{
    padding: 0px !important;
}

.custom-background-pricing{
    background-size: initial;
    background-position: right 15% bottom 20%;
    background-image: linear-gradient(180deg,rgb(0, 133, 202) 0%,#36508e 100%)!important;
    padding: 60px;
}

.custom-title-pricing{
    font-size: 35px !important;
    color: white !important;
    font-family: Nunito;
    font-weight: 700;
    line-height: 1.3em;
    text-align: center;
}

.custom-description-pricing{
    font-family: Nunito;
    font-size: 17px !important;
    line-height: 1.8em;
    color: white !important;
}

.custom-description-grading{
    font-family: Nunito;
    font-size: 18px !important;
    line-height: 1.8em;
    color: white !important;
}

.custom-description-grading-black{
    font-family: Nunito;
    font-size: 18px !important;
    line-height: 1.8em;
    color: black !important;
}

.custom-subtitle-grading2{
    font-family: Nunito;
    font-weight: 700;
    font-size: 36px;
    color: white!important;
    letter-spacing: 2px;
    line-height: 1.4em;
    text-align: center;
}
.custom-subtitle-grading{
    font-family: Nunito;
    font-weight: 700;
    font-size: 36px;
    color: black!important;
    letter-spacing: 2px;
    line-height: 1.4em;
    text-align: center;
}


.custom-pricing-column{
    background-color: #fff;
}

.custom-grading-column{
    background-color: #042644;
}
.custom-padding-carousel{
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.titulo-inicio{
    padding-bottom: 10px;
    font-weight: 700;
    font-size: 45px;
    color: #333;
    font-family: Roboto;
    text-align: center;
}

.parrafo-inicio{
    text-align: justify;
    font-family: 'Nunito Sans';
    font-size: 17px;
    line-height: 34px;
    color: black;
}

.parrafo-inicio strong{
    font-weight: 700;
}

.titulo-slider{
    font-family: 'Nunito Sans';
    font-weight: 800;
    font-size: 56px;
    line-height: 1.4em;
    text-align: left;
    color: white !important;
    text-transform: uppercase;
}

.text-slider{
    font-family: Nunito;
    font-size: 21px !important;
    line-height: 1.8em;
    color: white !important;
}

.fondo-slider{
    background-image: url(https://previews.123rf.com/images/scheriton/scheriton1111/scheriton111100011/11275347-noche-bastante-azul-cielo-con-estrellas-y-luces-de-fondo.jpg);
}

.padding-md-left{
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
}

ken-burns-carousel{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #fff;
}
ken-burns-carousel div {
    height: 800px;
    width: 100%;
    z-index: 1 !important;
}

.footer-container{
    padding-top: 50px;
    padding-left: 70px;
    padding-right: 70px;
    padding-bottom: 20px;
    background-color: #191919!important;
}
.footer-copyright-text {
    color: white;
    font-size: 15px;
    font-weight: 500;
    line-height: 25px;
}
.footer-link{
    color: white;
    font-size: 15px;
}
.footer-link:hover{
    color: rgb(15, 175, 255);
}
.footer-header{
    color: white;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 1.5px;
    line-height: 18px;
}
.footer-icons{
    font-size: 1.5em;
    line-height: .04167em;
    vertical-align: -.125em;
}
.footer-icons:hover{
    color: rgb(15, 175, 255);
}
.footer-column{
    margin-bottom: 20px;
}
.slider-imagen{
    width: 100% !important;
}
.border-eg-blue{
    border: solid 1px #36508e;
}

.custom-backgroud-black{
    background-color: #000000;
}
.custom-gold{
    color: #D1B000 !important;
}
.custom-borders-gold{
    border: solid 4px #D1B000;
    border-radius: 10px;
}
.custom-new-servicio{
    color: red;
    font-size: 18px;
    margin-bottom: 10px;
}
@media only screen and (min-width: 992px){
    .col-md-35 {
        flex: 0 0 auto;
        width: 30% !important;
    }
    .slider-imagen{
        height: 700px !important;
        width: 100% !important;
        margin-left: 150px;
    }
    .footer-column{
        padding-left: 50px;
    }
    .lg-justify-content{
        justify-content: space-around !important;
    }
    .custom-menu-title-color{
        color: #36508e;
    }
    .custom-menu-title-color:hover{
        color: rgb(0, 133, 202) !important;
    }
    .custom-menu-item-hover-bg:hover{
        background-color: none;
    }
    
    .custom-menu-hover-link:hover{
        color: rgb(0, 133, 202) !important;
    }
    
    .menu-state-bg .menu-item.show > .menu-link {
        background-color: #ffffff !important;
    }
    .menu-state-bg .menu-item.show > .custom-menu-title-color {
        color: rgb(0, 133, 202) !important;
    }

    .custom-title-pricing{
        color: white !important;
        font-family: Nunito;
        font-weight: 700;
        font-size: 45px !important;
        color: #FFFFFF!important;
        line-height: 1.3em;
        text-align: center;
    }
    .padding-md-left{
        padding-left: 250px !important;
        padding-top: 5rem !important;
        padding-bottom: 5rem !important;
    }
    .top_bar i {
        font-size: 20px !important;
        padding-right: 5px;;
    }
    .margin-md-top{
        margin-top: 19rem !important;
    }
    
}

.carousel-item{
    width: auto !important;
}
.carousel-item-next, .carousel-item-start, .carousel-item-end{
    opacity: 0 !important;
}
.carousel-fade .carousel-item-prev.carousel-item-end{
    opacity: 0 !important;
}
@media only screen and (max-width: 991px){
    .dropdown-item .custom-submenu-movil-text{
        color: #ffffff !important;
    }
    .dropdown-item:hover .custom-submenu-movil-text:hover{
        color: #000000 !important;
    }
    .carousel-item {
        margin-right: 0 !important;
    }
    .menu-movil{
        z-index: 1001 !important;
        margin-top: 100px !important;
    }
    .enviar-cartas-link-menu{
        color: rgb(0, 133, 202);
        background-color: #ffffff !important;
        border: solid 1px #36508e;
        border-radius: 0.475rem;
    }
    .custom-image-grading{
        width: 100% !important;
    }
    .banner_temporal {
        top: 105px !important; 
    }
}
.custom-text-color-white .form-label{
    color: #ffffff !important;
}
.custom-text-label {
    color: #ffffff !important;
    font-size: 1.05rem !important;
    margin-bottom: 0.5rem;
    font-weight: 500 !important;
}
.portada {
    text-align: center;
    margin: 0 auto;
    display: block;
    width: 100%;
}
.portada div {
    margin: 0;
    padding: 0;
}
.portada div .circle {
    display: inline-block;
    padding: 3px;
    margin-bottom: 6px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}
.portada div a {
    text-align: center;
    margin: 0 auto;
    color: #040668;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: -1px;
    font-size: 16px;
}
.portada div a img {
    max-height: 90px;
    vertical-align: middle;
}
.portada div .circle span {
    display: block;
    text-align: center;
    margin: 0 auto;
    line-height: 140px;
    height: 145px;
    width: 145px;
    -moz-border-radius: 70px;
    -webkit-border-radius: 70px;
    border-radius: 70px;
    border: 4px solid #FFF;
    -webkit-transition: 0.8s ease-in-out;
    -moz-transition: 0.8s ease-in-out;
    -o-transition: 0.8s ease-in-out;
    transition: 0.8s ease-in-out;
    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    -o-transition-property: -o-transform;
    transition-property: transform;
    overflow: hidden;
}

.portada div .circle span img:hover{
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
}

.portada div .circle span img{
    -webkit-transition: 0.8s ease-in-out;
    -moz-transition: 0.8s ease-in-out;
    -o-transition: 0.8s ease-in-out;
    transition: 0.8s ease-in-out;
    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    -o-transition-property: -o-transform;
    transition-property: transform;
    overflow: hidden;
}

.boton {
    background: #161B2E;
    color: #fff;
}
.boton h2{
    color: #fff !important;
}
.boton h3{
    color: #fff !important;
}

.card-album-datos {
    color: #36508e;
    font-size: 14px;
    font-weight: 800;
}

.top_bar {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 30;
    line-height: 25px;
}

.top_bar a {
    font-size: 20px;
    padding-right: 5px;
}

.top_bar .top-bar-link{
    font-size: 12px;
    color: #ffffff;
}

.top_bar i {
    font-size: 15px;
    padding-right: 5px;;
}

.easy-autocomplete {
    width: auto!important;
    position: relative;
}

.easy-autocomplete input{
    padding: 0.75rem 1rem !important;
}

.scrolltop_whatsapp{
    right: 65px !important;
    background-color: #25D366 !important;
}

.header_etiqueta_card {
    font-family: Calibri;
    font-size: 28px;
    line-height: 15px;
    margin-left: 1.25rem !important;
}

.header_etiqueta_notas {
    font-family: Calibri;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    line-height: 15px;
}

.header_etiqueta_border {
    border-top: solid 2px #D1B000;
    border-bottom: solid 2px #D1B000;
    padding-top: 10px !important;
    padding-bottom: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.header_etiqueta_border_centro {
    border-right: solid 4px #D1B000;
}

.header_etiqueta_certificado {
    font-family: Arial;
    font-size: 31px;
    margin-bottom: 0px;
}

.header_etiqueta_grade {
    font-size: 64px;
    margin-bottom: 10px !important;
    line-height: 64px;
    font-weight: 700;
    font-family: Arial;
}

.header_etiqueta_global {
    font-size: 28px;
    font-family: Arial;
    font-weight: 700;
    letter-spacing: 1px;
    margin-bottom: 0 !important;
}

.header_etiqueta_global_container {
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.contenedor-barras img{
    width: 100% !important;
}

@media only screen and (max-width: 1024px){
    .header_etiqueta_card {
        font-size: 22px;
        line-height: 19px;
        margin: 0.5rem !important;
    }
    
    .header_etiqueta_notas {
        font-size: 18px;
        line-height: 12px;
    }

    .header_etiqueta_certificado {
        font-family: Arial;
        font-size: 22px;
        margin-bottom: 0px;
    }

    .header_etiqueta_grade {
        font-size: 50px;
        margin-bottom: 10px !important;
        line-height: 55px;
        font-weight: 700;
        font-family: Arial;
    }

    .header_etiqueta_global {
        font-size: 19px;
        font-family: Arial;
        font-weight: 700;
        letter-spacing: 1px;
        margin-bottom: 0 !important;
    }

    .header_etiqueta_global_container {
        padding-left: 1px !important;
        padding-right: 1px !important;
        margin-top: 5px !important;
    }
}

.etiqueta_image_container {
    margin-bottom: 20px;
}

.descuento_uso_titulo {
    margin-bottom: 0.5rem;
    font-size: 1.1rem;
    font-weight: 600;
    color: #3F4254;
}
.descuento_uso_codigo{
    font-size: 1.5rem;
}
.descuento_uso_accion{
    color: red;
    font-size: 1.1rem;
}

.descuento_total {
    color: red !important;
    font-size: 1.3rem !important;
    font-weight: 700 !important;
}

.banner_temporal {
    height: auto; 
    background-image: linear-gradient(90deg,#36508e 0%,rgb(0, 133, 202) 100%);
    position: sticky; 
    top: 117px; 
    text-align: center;
    padding: 10px 0; 
    z-index: 100;
    vertical-align: middle;
}
