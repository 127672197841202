.lds-ring {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
}

.lds-ring-container{
  width:64px;
  height:64px;
}

.lds-ring-container.condensed{
  position:absolute;
  width:30px;
  height:30px;
  right:0;
}

.lds-ring-parent.text-center .lds-ring-container{
  margin:auto;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 70%;
  height: 70%;
  margin: 10%;
  border: 4px solid #f00;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #f00 transparent transparent transparent;
}
.lds-ring div:nth-child(1) { animation-delay: -0.45s; }
.lds-ring div:nth-child(2) { animation-delay: -0.3s; }
.lds-ring div:nth-child(3) { animation-delay: -0.15s; }
@keyframes lds-ring {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}